// src/utils/sendEmail.js
import emailjs from 'emailjs-com';

// Function to send email using EmailJS for user details
export const sendEmail = async (ownerEmail, password, ownerName, startDate, endDate, amount) => {
  const templateParams = {
    to_email: ownerEmail,
    username: ownerEmail,
    password: password,
    ownerName: ownerName,
    startDate: startDate,
    endDate: endDate,
    amount: amount,
  };

  try {
    await emailjs.send('service_9lslyi2', 'template_2t7erac', templateParams, '14_ths1xYdpFcvGAU');
    console.log('Email sent successfully');
  } catch (error) {
    console.error('Error sending email:', error);
    throw new Error('Failed to send email');
  }
};

// New Function to send password reset email with a custom link via EmailJS
export const sendPasswordResetEmailWithLink = async (email, resetLink) => {
  const templateParams = {
    to_email: email,  // User's email
    reset_link: resetLink,  // The password reset link generated by Firebase
  };

  try {
    // Use a different EmailJS template for the password reset email
    await emailjs.send('service_9lslyi2', 'template_oal8w16', templateParams, '14_ths1xYdpFcvGAU');
    console.log('Password reset email sent successfully');
  } catch (error) {
    console.error('Error sending password reset email:', error);
    throw new Error('Failed to send password reset email');
  }
};
